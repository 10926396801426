import { filterOutNullOrUndefined, slugify } from '@/lib/utils';
import { TRAVEL_TYPE_ICONS, PRODUCT_LABEL_NAMES } from '@/lib/variables';

import { Journey } from '@/lib/strapi-types/Journey';

import { mockJourneyInfos } from '@/lib/mock/product';

import TRAVEL_STYLE_NAME from '../enums/travelStyleName.enum';
import { JourneyModel, JourneyType, ItineraryStep } from '../models/journey';

import parseHTMLToText from './fieldsParsers/parseHTMLToText';

import removeStyle from '@/utils/removeStyles';

// Asserts that a Strapi Journey is passed
export function isJourney(obj: any): obj is Journey {
  if (!obj || typeof obj !== 'object') {
    return false;
  }

  const attributes = obj.attributes as Journey['attributes'];
  return (
    obj.id !== undefined &&
    typeof obj.id === 'number' &&
    typeof attributes === 'object' &&
    typeof attributes.title === 'string' &&
    (attributes.identifier === undefined || typeof attributes.identifier === 'string') &&
    (attributes.enabled === undefined || typeof attributes.enabled === 'boolean') &&
    (attributes.categories === undefined || Array.isArray(attributes.categories.data))
  );
}

export default function parseJourney(journey: Journey): JourneyModel {
  const journeyData = journey.attributes;

  const country = getCountryByJourney(journey);

  const geographicZone = getGeographicZoneByJourney(journey);

  const travelTypeName = journeyData.generalTravelType
    ? TRAVEL_STYLE_NAME[journeyData.generalTravelType]
    : '';

  const travelTypeIcon = journeyData.generalTravelType
    ? TRAVEL_TYPE_ICONS[journeyData.generalTravelType]
    : '';

  const steps: ItineraryStep[] =
    journeyData.watabiContent?.sequence?.map(step => ({
      totalHours: 0,
      id: step.id.toString(),
      title: step.title ?? '',
      text: (step.text ?? []).map(paragraph => `<p>${paragraph}</p>`).join(''),
      stepNumber: step.index ?? -1,
      description: removeStyle(step.day_title[0]?.text ?? ''),
      startLocation: step.location_sequence[0]?.name ?? step.title ?? '',
      endLocation: step.location_sequence[1]?.latitude ? step.location_sequence[1].name : undefined,
      images: step.mediaForSequence ? [step.mediaForSequence.image] : [],
      startDayNumber: filterOutNullOrUndefined(step.begin) ? step.begin + 1 : -1,
      endDayNumber: filterOutNullOrUndefined(step.end) ? step.end + 1 : -1,
      locations: step.location_sequence.map(locationSequence => ({
        name: locationSequence.name,
        lat: parseFloat(locationSequence.latitude ?? '0'),
        lng: parseFloat(locationSequence.longitude ?? '0')
      })),
      dayHighlights: step.day_title.map(day => ({
        title: day.title ?? '',
        text: day.text ?? ''
      }))
    })) ?? [];

  const mapLocation = journeyData.watabiContent?.location.find(
    location => location.latitude !== '0' && location.longitude !== '0'
  );

  return {
    id: journey.id.toString(),

    slug: journey.attributes.slug ?? slugify(journey.attributes.title),

    // TODO: Add isLiked when ready
    isLiked: false,

    geographicZoneSlug: geographicZone?.attributes.urlKey,

    label: journeyData.label ? PRODUCT_LABEL_NAMES[journeyData.label] : undefined,

    path: country
      ? getJourneySlug({
          destination: country,
          journey
        })
      : '',

    imageSlugs: journeyData.imageSlugs ?? [],

    days: journeyData.days ?? 0,

    nights: journeyData.nights ?? 0,

    // Add ratings when ready
    ratings: {
      count: 0,
      average: 0,
      ratings: []
    },

    price: journeyData.price ?? 0,

    name: journeyData.title,

    // TODO: Add isRecommended when ready
    isRecommended: false,

    // TODO: Add type when ready
    type: journeyData.tag as JourneyType,

    startDate: journeyData.departureDate,

    countryName: country ? `${country.attributes.mdvDstArticle} ${country.attributes.title}` : '',

    typeCategories: {
      slug: journeyData.generalTravelType ?? '',
      name: travelTypeName,
      icon: travelTypeIcon
    },

    description: {
      title: '',
      content: journeyData.shortDescription ?? '',
      showPicto: false
    },
    sapeigReference: journeyData.sapeigReference,

    longDescription: journeyData.longDescription ?? '',

    country: {
      id: country?.id ?? 0,
      label: country?.attributes.title ?? '',
      code: country?.attributes.mdvDstMapsShortname ?? '',
      codeIso: country?.attributes.mdvDstMapsShortname ?? '',
      bestPeriods: country?.attributes.bestPeriods ?? [],
      averageFlightTime: country?.attributes.averageFlightTime ?? undefined,
      jetlag: country?.attributes.jetlag ?? undefined,
      visa: country?.attributes.visa ?? undefined
    },

    keyPoints: journeyData.marketingDescriptionKeyPoints
      ? (journeyData.marketingDescriptionKeyPoints.match(/<li[^>]*>(.*?)<\/li>/g) ?? []).map(li =>
          parseHTMLToText(li.replace(/<\/?li[^>]*>/g, ''))
        )
      : [],

    locations:
      journeyData.watabiContent?.location
        .filter(location => location.type === 'City')
        .map(location => parseHTMLToText(location.name ?? '')) ?? [],

    budget: {
      included: journeyData.pricesIncludes ?? '',
      notIncluded: journeyData.pricesIncludesNot ?? '',
      conditions: journeyData.pricesSpecialCond ?? ''
    },

    // Add infos when ready
    infos: mockJourneyInfos,

    // Add steps when coordinates ready
    steps,
    useSteps: journeyData.useJsonItinerary ?? false,

    includedBenefits: journeyData.pricesOtherInfo ?? '',

    // Map location for country page
    mapLocation: mapLocation
      ? {
          id: mapLocation.name ?? '',
          locations: [
            {
              lat: parseFloat(mapLocation.latitude ?? '0'),
              lng: parseFloat(mapLocation.longitude ?? '0')
            }
          ]
        }
      : undefined,
    reinsurances: journeyData.reinsurances?.data.map(e => e.attributes)
  };
}
